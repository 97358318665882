import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Widget from './style'
import { SuperTag, Link } from '~components';
import VideoJSThree from '../Videojs/VideoJSThree';

export default function ProcessToProfitWidget(){
  
return(
      
<Widget>
  <Container>
  <Widget.Block>
    <Row className=" justify-content-lg-start">
    <Col xs="12" className="col-xl-5 col-lg-5 col-md-8 col-xs-10">
    <Widget.Image>
    <VideoJSThree videoId="ob521vqot3" wrapper="wistia-player-container-5"/>
    </Widget.Image>
      </Col>
    <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
        <Widget.Box>
        <Link to="/customer-stories"><Widget.MiniHeading><Widget.BackButton textColor="#000"><i class='fas fa-angle-left'></i>  Customer Stories</Widget.BackButton> </Widget.MiniHeading></Link>
          <Widget.Heading as="h2"> <SuperTag value="How Process to Profits Saves Its Client’s Time With We-Connect"/> </Widget.Heading>
        </Widget.Box>
      </Col>
    </Row>
    </Widget.Block>

    <Widget.Block>
    <Row className="justify-content-lg-start">
    <Col xs="12" className="col-xl-4 col-lg-4 col-md-8 col-xs-10">
        <Widget.Card>
        <Widget.Company as="h2">Process to Profits</Widget.Company>
        <Widget.Text>Process to Profits is a business development and consulting company helping small businesses break the six-figure plateau.</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara className="rsc"> <b>Industry</b> </Widget.SubPara>
        <Widget.Text>Business Development & Consulting</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara className="rsc"> <b>Company Size</b> </Widget.SubPara>
        <Widget.Text> 2-10 employees</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara className="rsc"> <b>Features Used</b> </Widget.SubPara>
        <Widget.Text>Smart sequences, automated follow-ups</Widget.Text>
        </Widget.Card>
      </Col>
    <Col className="col-xl-7 col-lg-7 col-md-8 col-xs-10">

        <Widget.Title as="h2"><b>A Sustainable Approach To Lead Generation</b></Widget.Title>
        <Widget.Text>Before We-Connect, Kevin Wathey, founder of Process to Profits, tried his hand at manual prospecting. In an effort to find more time in his day, he delegated this task to his team. “It was a very time-consuming and troublesome process,” he told us. 
        </Widget.Text>
        <Widget.Text>
        Since using We-Connect for a little under a year, Kevin has managed to simplified his customer intake process, generate thousands of conversations, and, most importantly, free up time in his day.
        </Widget.Text>
        <Widget.SubPara>
        Watch our interview with Kevin Wathey and learn how he’s getting the most out of We-Connect:
        </Widget.SubPara>
      </Col>
    </Row>
    </Widget.Block>
    
  </Container>
</Widget>

)
}